import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const ContactPage: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh", // Full viewport height
        bgcolor: "lightcyan",
        p: 4, // Padding
      }}
    >
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          CONTACT US
        </Typography>
        <Box>
          <Typography variant="body1" gutterBottom>
            Email:{" "}
            <Typography component="span" variant="body2">
              hr@falcon-sol.co.in
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Contact:{" "}
            <Typography component="span" variant="body2">
              +919663456014
            </Typography>
          </Typography>
          <Typography variant="body1">
            Address:{" "}
            <Typography component="span" variant="body2">
              Doddakannelli, Bangalore, Karnataka - 560035
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactPage;
