import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../pages/Header";
import Home from "../pages/Home";
import RecruitmentSolution from "../pages/Services";
import ContactPage from "../pages/ContactUs";

const AppContent: FC = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<RecruitmentSolution />} />
        <Route path="/contactus" element={<ContactPage />} />
      </Routes>
    </>
  );
};

export default AppContent;
