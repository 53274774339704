import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import exampleImage from "../images/team1.png";

const Home = () => {
  return (
    <Grid container style={{ minHeight: "100vh", backgroundColor: "lightcyan" }}>
      {/* Left Grid item for image */}
      <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Card style={{ maxWidth: "80%", margin: "auto" }}>
          <CardMedia
            component="img"
            image={exampleImage}
            alt="Example Image"
            style={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "lightcyan",
            }}
          />
        </Card>
      </Grid>
      {/* Right Grid item for text content */}
      <Grid
        item
        xs={12}
        md={8}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <CardContent>
          <Typography variant="h4" sx={{ fontFamily: "Comic Sans MS" }}>
            About us:
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Comic Sans MS", fontSize: "1rem" }}
            color="text.secondary"
            paragraph
          >
            Falcon Management Solutions is a complete recruitment service provider that offers a distinct and comprehensive array of recruitment solutions. We are dedicated to offering complete support and tailored solutions to clients for meeting their end-to-end recruitment needs for all domains. Being the one-stop-shop for all the recruitment solutions in Pan India, our belief is in providing seamless and effortless quality services to every recruitment requirement under one platform. You just name it and we will do it for you. We do the hiring for IT and Non-IT sectors for all the required positions.
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Comic Sans MS", fontSize: "1rem" }}
            color="text.secondary"
            paragraph
          >
            Falcon Management Consulting specializes in helping start-ups set up their business from scratch by providing all levels of recruitment services in multiple functional areas including IT, Sales & Marketing, Finance, Services, etc. We also take on assignments where we help organizations in setting up HR processes and training. Our clients have reaped the benefits through better candidates deputing at a faster pace.
          </Typography>
          <Typography variant="h4" sx={{ fontFamily: "Comic Sans MS" }}>
            Vision:
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Comic Sans MS", fontSize: "1rem" }}
            color="text.secondary"
            paragraph
          >
            To establish a paradigm of complete manpower solutions by providing the best suitable recruitment solutions for our clients.
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default Home;
