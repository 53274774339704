import { Card, CardContent, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import exampleImage from "../images/team2.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const RecruitmentSolution = () => {
  return (
    <Grid container sx={{ minHeight: "100vh", backgroundColor: "lightcyan" }}>
      {/* Left Grid item for image */}
      <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Card sx={{ maxWidth: "80%", margin: "auto" }}>
          <CardMedia
            component="img"
            image={exampleImage}
            alt="Example Image"
            sx={{
              height: "auto",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "lightcyan",
            }}
          />
        </Card>
      </Grid>
      {/* Right Grid item for text content */}
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightcyan",
          padding: "20px",
        }}
      >
        <CardContent>
          <Typography variant="h4" sx={{ fontFamily: "Comic Sans MS" }}>
            Recruitment Solution:
          </Typography>
          <List sx={{ fontFamily: "Comic Sans MS", fontSize: "1.2rem", marginTop: "10px", marginBottom: "20px" }}>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Permanent Staffing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Contract Staffing" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Leadership Hiring" />
            </ListItem>
          </List>
          <Typography variant="h4" sx={{ fontFamily: "Comic Sans MS" }}>
            Our Recruitment Process:
          </Typography>
          <List component="ol" sx={{ fontFamily: "Comic Sans MS", fontSize: "1.2rem", marginTop: "10px" }}>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Discuss the hiring needs of clients." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Conduct market research for the requirements." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Present sample selections for client approval." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Search and screen the right profiles through a dedicated SPOC." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Schedule interviews and facilitate candidate selection." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Handle salary negotiations and documentation." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Ensure timely hiring of the best skilled talent." />
            </ListItem>
          </List>
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default RecruitmentSolution;
