import React, { FC } from "react";

import AppContent from "./AppContent";

import "./App.css";

const App: FC = () => {
  return <AppContent />;
};

export default App;
